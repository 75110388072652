<script setup>
import gsap from "gsap";
</script>

<template>
  <div class="home">
    <section class="hero">
      <div class="hero-content">
        <div class="left-part">
          <h1 id="site-caption">Fredrik Hernegren</h1>
          <p id="site-description">
            Product manager and UX Designer with a passion for making products
            that create real value
          </p>
        </div>
        <div class="right-part">
          <img
            src="@/assets/fredrikbubble600x600_bw.png"
            id="fredrik"
            alt="Fredrik"
          />
        </div>
        <div
          id="down-arrow"
          style="
            font-size: xx-large;
            text-align: center;
            grid-column: 1 / span2;
            grid-row: 2;
          "
        >
          ↓
        </div>
      </div>
    </section>
    <WordCloud />
    <SummarySection />
    <AboutSection />
    <PortfolioSection id="portfolio-section" />
    <ConnectSection />
  </div>
</template>

<script>
// @ is an alias to /src
import AboutSection from "@/components/AboutSection.vue";
import PortfolioSection from "@/components/PortfolioSection.vue";
import ConnectSection from "@/components/ConnectSection.vue";
import WordCloud from "@/components/WordCloud.vue";
import SummarySection from "@/components/SummarySection.vue";

var backNav = "#battlefront";

export default {
  name: "HomeView",
  components: {
    AboutSection,
    PortfolioSection,
    ConnectSection,
  },
  props: {
    backNavAnchor: String,
  },
  methods: {
    scrollToElement(elId) {
      const el = document.getElementById(elId);
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getBackNavAnchor() {
      return this.backNavAnchor;
    },
  },
  mounted() {
    if (!this.backNavAnchor) {
      backNav = "#none";
    } else {
      backNav = "#" + this.backNavAnchor;
    }
    onpageshow(backNav);
  },
};

function onpageshow(backNav) {
  gsap.from("#site-caption", {
    translateX: -200,
    opacity: 0.0,
    duration: 0.75,
    ease: "elastic(1,0.75)",
  });
  gsap.from("#fredrik", {
    scale: 2,
    rotation: 90,
    opacity: 0.0,
    duration: 1.55,
    ease: "elastic(1,0.75)",
  });
  gsap.from("#site-description", {
    translateX: -400,
    opacity: 0.0,
    duration: 0.75,
    ease: "elastic(1,0.75)",
  });
  gsap.from("#more-button", {
    translateX: -400,
    opacity: 0.0,
    duration: 0.75,
    ease: "elastic(1,0.75)",
    clearProps: "all",
  });

  gsap.from("#down-arrow", {
    opacity: 0,
    delay: 2,
    duration: 5,
    ease: "power2.out",
  });

  gsap.to("#down-arrow", {
    translateY: -50,
    duration: 0.75,
    repeat: -1,
    ease: "power2.out",
    yoyo: true,
  });

  gsap.fromTo(
    backNav + " div",
    {
      scale: 2,
      opacity: 0,
    },
    {
      scale: 1,
      opacity: 1,
      duration: 0.25,
      ease: "linear",
      overwrite: true,
      inherit: false,
      clearProps: "transform",
    }
  );

  /*.to(".logo", {
      rotation: 360,
      duration: 2,
      ease: "bounce.out",
    })*/
}
</script>

<style>
.hero {
  background-color: #f3f8f3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh;
  margin: 0 auto;
}

/* Hero section styles */
.hero-content {
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  align-items: center; /* Vertically center the content */
  height: 100%;
  margin-top: 128px;
}

@media (min-width: 768px) {
  .hero-content {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    align-items: center; /* Vertically center the content */
    height: 100%;
    width: 60vw;
    margin: 0 auto;
    margin-top: 128px;
  }

  .left-part h1 {
    font-size: 88px;
  }
}

.left-part {
  display: grid;
  padding: 20px;
  color: var(--text-color);
}

.left-part h1 {
  line-height: 80%;
  margin-bottom: 10px;
  text-align: left;
}

.left-part p {
  font-size: 24px;
}

.right-part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-part img {
  max-width: 60%;
  height: auto;
  border-radius: 50%;
}
</style>
