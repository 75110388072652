<template>
  <section id="word-cloud" class="content-wide">
    <div>
      <span class="word-cloud-item strategy-item">Product vision</span>
      <span class="word-cloud-item strategy-item">Product strategy</span>
      <span class="word-cloud-item strategy-item">Roadmaps</span>
      <span class="word-cloud-item strategy-item">Impact maps</span>

      <span class="word-cloud-item analytical-item">Metrics</span>
      <span class="word-cloud-item analytical-item">User studies</span>
      <span class="word-cloud-item analytical-item">Design thinking</span>
      <span class="word-cloud-item analytical-item">Personas</span>
      <span class="word-cloud-item analytical-item"
        >Competition monitoring</span
      >

      <span class="word-cloud-item ux-item">Prototyping</span>
      <span class="word-cloud-item ux-item">User testing</span>
      <span class="word-cloud-item ux-item">Design frameworks</span>
      <span class="word-cloud-item ux-item">A/B testing</span>
      <span class="word-cloud-item ux-item">Hi-fi mockups</span>

      <span class="word-cloud-item team-item">User journeys</span>
      <span class="word-cloud-item team-item">Workshops</span>
      <span class="word-cloud-item team-item">Stakeholder management</span>
      <span class="word-cloud-item team-item">Team leadership</span>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "WordCloud",
  methods: {
    name() {},
  },
  mounted() {
    gsap.from(".strategy-item", {
      //y: -(xPosStrategy - xPosWordCloud),
      x: 100,
      opacity: 0,
      duration: 0.25,
      ease: "linear.out",
      stagger: 0.1,
      scrollTrigger: {
        markers: false,
        trigger: "#word-cloud",
        start: "center bottom",
      },
    });
    gsap.from(".analytical-item", {
      //y: -(xPosAnalytic - xPosWordCloud),
      x: 100,
      opacity: 0,
      duration: 0.25,
      ease: "linear.out",
      stagger: 0.1,
      scrollTrigger: {
        markers: false,
        trigger: "#word-cloud",
        start: "center bottom",
      },
    });
    gsap.from(".ux-item", {
      //y: -(xPosAnalytic - xPosWordCloud),
      x: 100,
      opacity: 0,
      duration: 0.25,
      ease: "linear.out",
      stagger: 0.1,
      scrollTrigger: {
        markers: false,
        trigger: "#word-cloud",
        start: "center bottom",
      },
    });
    gsap.from(".team-item", {
      //y: -(xPosAnalytic - xPosWordCloud),
      x: 100,
      opacity: 0,
      duration: 0.25,
      ease: "linear.out",
      stagger: 0.1,
      scrollTrigger: {
        markers: false,
        trigger: "#word-cloud",
        start: "center bottom",
      },
    });
  },
};
</script>

<style>
#word-cloud {
  min-height: 30vh;
  background-color: var(--background-color);
}

.word-cloud-item {
  display: inline-block;
  color: var(--background-color);
  font-size: 15px;
  padding: 16px;
  margin: 12px;
  border-radius: 32px;
  font-weight: 500;
}

#word-cloud .strategy-item {
  background-color: var(--color-blue) !important;
}

#word-cloud .team-item {
  background-color: var(--color-red) !important;
}

#word-cloud .ux-item {
  background-color: var(--color-yellow) !important;
}

#word-cloud .analytical-item {
  background-color: var(--color-green) !important;
}
</style>
