<script setup>
import BlueprintImage from "../assets/blueprint/blueprint_teaser.png";
import VolvoImage from "../assets/volvo/volvo_teaser.png";
import BattlefrontImage from "../assets/battlefront/battlefront_teaser.png";
import AxiomaticsImage from "../assets/axiomatics/axiomatics_teaser.png";
</script>

<template>
  <section class="content" id="selected-work">
    <h2>Selected work</h2>
  </section>
  <section class="content-wide" id="grid-section">
    <div class="grid">
      <PortfolioGridItem
        itemid="spark-blueprint"
        route="/spark-blueprint"
        :image="BlueprintImage"
        header="Spark Blueprint"
        description="Interior design application, 2020"
      />
      <PortfolioGridItem
        itemid="volvo-app"
        route="/volvo-app"
        :image="VolvoImage"
        header="Volvo Cars app"
        description="Companion app, 2017"
      />
      <PortfolioGridItem
        itemid="star-wars-battlefront"
        route="/star-wars-battlefront"
        :image="BattlefrontImage"
        header="Star Wars Battlefront"
        description="Video game, 2015"
      />
      <PortfolioGridItem
        itemid="axiomatics"
        route="/axiomatics"
        :image="AxiomaticsImage"
        header="Axiomatics Policy Editor"
        description="SaaS tool, 2014"
      />
    </div>
  </section>
</template>

<script>
import battlefrontTeaserImg from "@/assets/battlefront/battlefront_teaser.png";
import PortfolioGridItem from "./PortfolioGridItem.vue";

export default {
  name: "PortfolioSection",
  data() {
    return {
      image: {
        battlefrontTeaser: battlefrontTeaserImg,
      },
    };
  },
  components: { PortfolioGridItem },
};
</script>
