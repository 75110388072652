<template>
  <section id="summary" class="content-wide">
    <h2>About</h2>
    <div class="grid-about" style="margin-top: 48px">
      <div class="grid-item-static item-list">
        <div class="right">
          <ul>
            <b>Experience</b>
            <li>Product Manager, 6+ years</li>
            <li>UX Designer, 8+ years</li>
            <li>Consumer apps, SaaS products, video games and more</li>
            <li>Essential knowledge of technologies and programming</li>
          </ul>

          <ul>
            <b>Qualities</b>
            <li>Analytical and empathetic</li>
            <li>A positive and self-motivated team player</li>
            <li>Driven by solving complex problems</li>
            <li>Creative and curious</li>
            <li>Quality-minded</li>
          </ul>
        </div>

        <div class="left">
          <a
            href="/Fredrik_Hernegren_resume.pdf"
            target="_blank"
            id="cv-button"
            class="button-secondary"
          >
            <span class="icon grey" id="summary-icon">📄</span>

            Download resume (PDF)</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SummarySection",
};
</script>

<style scoped></style>
