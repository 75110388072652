<template>
  <section id="about" class="content-wide">
    <h2>How I work</h2>
    <div class="grid-about">
      <div class="grid-item-static item-one" id="strategy-block">
        <div class="left">
          <span class="icon blue" id="icon-outcomes">🎯</span>
          <h3>Outcomes</h3>
        </div>
        <div class="right">
          <p>
            I break down and translate business goals into product metrics.
            Metrics enables a focus on outcomes over outputs.
          </p>
          <p>
            I drive the work to make assumptions, build hypotheses and generate
            ideas on how the outcomes can be achieved together with the team.
            This generates features and requirements which are pieced together
            to MVPs & deliverables. I make sure that we measure to confirm that
            we are getting the outcomes we want.
          </p>
        </div>
      </div>
      <div class="grid-item-static item-two" id="analytic-block">
        <div class="left">
          <span class="icon green" id="icon-opportunities">🔎</span>
          <h3>Discovery</h3>
        </div>
        <div class="right">
          <p>
            I use my analytical mindset to drive discovery work by gathering,
            processing and presenting data. Whether it be product analytics,
            user studies, competition monitoring or input from customers and
            internal stakeholders.
          </p>
          <p>
            I love to get out of the building to meet and study the users. I
            find that this is always the best way to empathize with the user.
            Behaviors and pain-points give rise to new ideas and business
            opportunities.
          </p>
        </div>
      </div>
      <div class="grid-item-static item-three">
        <div class="left">
          <span class="icon yellow" id="icon-ux">🫖</span>
          <h3>UX</h3>
        </div>
        <div class="right">
          <p>
            From many years of working with UX it is constantly top of mind. I
            know what a great, high-quality product looks and feels like - and
            what it takes to get there.
          </p>
          <p>
            I can handle the complete scope from smallest UI component to
            wireframes, information hierarchies, usability testing and design
            frameworks. In the end, the user experience is what makes or breaks
            a product.
          </p>
        </div>
      </div>
      <div class="grid-item-static item-four">
        <div class="left">
          <span class="icon red" id="icon-coms">💬</span>
          <h3>Inspiration</h3>
        </div>
        <div class="right">
          <p>
            For teams to be motivated and efficient, I believe, everyone needs
            to know why and what we are working on. I use my communication
            skills and enthusiasm to inspire and align the team and stakeholders
            towards our common goals.
          </p>
          <p>
            I use my strategic and pragmatic sense to create product visions and
            craft roadmaps based on opportunities, market needs, dependencies,
            technical frameworks and available resources.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "AboutSection",
  props: {
    msg: String,
  },
  mounted() {
    //const xPosWordCloud = document.getElementById("word-cloud").offsetTop;

    //const xPosStrategy = document.getElementById("strategy-block").offsetTop;
    //const xPosAnalytic = document.getElementById("analytic-block").offsetTop;

    //const width = document.getElementById("strategy-block").offsetWidth;

    gsap.from("#summary-icon", {
      rotation: -90,
      duraion: 5,
      scrollTrigger: {
        markers: false,
        scrub: 1,
        trigger: "#summary-icon",
        start: "top 75%",
        end: "+=100",
      },
    });

    gsap.from("#icon-outcomes", {
      scale: 0,
      opacity: 0,
      rotation: 180,
      duration: 0.5,
      ease: "power2.out",
      scrollTrigger: {
        markers: false,
        trigger: "#icon-outcomes",
        start: "top 75%",
      },
    });
    gsap.from("#icon-opportunities", {
      scale: 0,
      opacity: 0,
      rotation: 180,
      duration: 0.5,
      ease: "power2.out",
      scrollTrigger: {
        markers: false,
        trigger: "#icon-opportunities",
        start: "center 75%",
      },
    });
    gsap.from("#icon-coms", {
      scale: 0,
      opacity: 0,
      rotation: 180,
      duration: 0.5,
      stagger: 2.5,
      ease: "power2.out",
      scrollTrigger: {
        markers: false,
        trigger: "#icon-coms",
        start: "center 75%",
      },
    });
    gsap.from("#icon-ux", {
      scale: 0,
      opacity: 0,
      rotation: 180,
      duration: 0.5,
      stagger: 2.5,
      ease: "power2.out",
      scrollTrigger: {
        markers: false,
        trigger: "#icon-ux",
        start: "center 75%",
      },
    });
    gsap.from("#cv-button .button-icon", {
      scale: 0.5,
      opacity: 0,
      rotationX: 90,
      duration: 0.25,
      ease: "power2.out",
      scrollTrigger: {
        markers: false,
        trigger: "#cv-button",
        start: "center 60%",
      },
    });
  },
};
</script>

<style>
.grid-about {
  margin-bottom: 48px;
  grid-template:
    "one" "two"
    "three" "four";
}

@media (min-width: 768px) {
  .grid-about {
    /*grid-template:
      "list list"
      "one two"
      "three four"; */
  }
}

.item-list {
  grid-area: list;
}

.item-one {
  grid-area: one;
}
.item-two {
  grid-area: two;
}
.item-three {
  grid-area: three;
}
.item-four {
  grid-area: four;
}

.grid-item-static {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  padding: 16px 24px;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .grid-item-static {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.grid-item-static .left {
  display: inline-block;
}

@media (min-width: 768px) {
  .grid-item-static .left {
    margin: auto;
    width: auto;
    text-align: center;
    display: block;
  }
}

.grid-item-static h3 {
  font-weight: 500;
  margin: 0;
  font-family: var(--font-body);
  display: inline-block;
}

@media (min-width: 768px) {
  .grid-item-static .h3 {
    display: block;
  }
}
.grid-item-static .icon {
  width: fit-content;
  background-color: transparent;
}

@media (min-width: 768px) {
  .grid-item-static .icon {
    font-size: 128px;
    border-radius: 50%;
    display: block;
    margin: auto;
  }
}

ul {
  list-style: circle;
}
.grid-item-static p {
}
</style>
