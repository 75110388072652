<template>
  <footer class="footer">
    <p>&copy; 2023 Isox AB</p>
  </footer>
</template>

<script>
export default {
  name: "PageFooter",
};
</script>

<style>
/* Footer styles */
.footer {
  background-color: var(
    --color-yellow-bg
  ); /* Background color for the footer */
  color: var(--text-color-light); /* Text color for the footer */
  text-align: center;
  margin-top: 96px;
  padding: 20px; /* Add padding to create space inside the footer */
}

.footer p {
  margin: 48px 0 0 0; /* Remove default margin for the paragraph */
  font-size: 14px;
  height: 96px;
}
</style>
