<template>
  <section class="content-wide">
    <h2>Connect</h2>
    <div id="connect">
      <a
        href="http://www.linkedin.com/in/fredrik-hernegren"
        target="_blank"
        class="button-secondary"
        ><span class="button-icon">💼</span>LinkedIn</a
      >

      <a href="tel:+46739851547" target="_blank" class="button-secondary"
        ><span class="button-icon">📞</span> +46 73 985 15 47</a
      >

      <a
        href="mailto:fredrik.hernegren@gmail.com"
        target="_blank"
        class="button-secondary"
        ><span class="button-icon">📩</span> fredrik.hernegren@gmail.com</a
      >
    </div>
  </section>
</template>

<style>
#connect {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
</style>

<script>
export default {
  name: "ConnectSection",
  props: {
    msg: String,
  },
};
</script>
