<script setup>
import gsap from "gsap";
</script>

<template>
  <router-link
    :to="route"
    :id="itemid"
    @click="onItemClick"
    @hover="onItemHover"
    style="text-decoration: none"
    :css="false"
  >
    <div
      class="grid-item"
      :style="{
        backgroundImage: 'url(' + image + ')',
      }"
    >
      <div class="grid_caption">
        <div>
          <h3>{{ header }}</h3>
          <p>{{ description }}</p>
        </div>
        <div class="read-more-icon">🔎</div>
      </div>
    </div>
  </router-link>
</template>
<style>
.grid-item {
  display: flex; /* Make the entire item a clickable block */
  text-decoration: none; /* Remove the default link underline */
  padding: 0;
  border-radius: 12px;
  background-color: var(--background-color-secondary);
  box-shadow: rgba(0, 0, 0, 0.075) 0 2px 8px;
  height: 488px;
  max-width: 488px;
  transition: transform 0.2s, box-shadow 0.2s, all 0.2s;
  color: var(--text-color);
  cursor: pointer;
}

.grid-item:hover {
  transform: translateY(-5px); /* Add a slight lift on hover */
  box-shadow: rgba(0, 0, 0, 0.075) 0 8px 8px;
}

.grid-item div h3 {
  text-decoration: none;
  font-weight: 900;
  margin: 0;
  font-family: var(--font-body); /* Use the heading font */
}

.grid-item div p {
  text-decoration: none;
  color: #777;
  font-family: var(--font-body); /* Use the body text font */
  margin: 0;
}

.grid_caption {
  align-self: flex-end;
  width: 100%;
  padding: 24px 24px 24px 24px;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: left;
  backdrop-filter: blur(20px);
  border-radius: 0 0 12px 12px;
  grid-template-columns: 4fr 1fr;
  display: grid;
}

.read-more-icon {
  margin: auto;
  font-size: 36px;
  font-family: var(--font-icon);
}
</style>

<script>
export default {
  name: "PortfolioGridItem",
  props: {
    itemid: String,
    route: String,
    image: String,
    header: String,
    description: String,
  },
  methods: {
    onItemClick() {
      gsap.to("#" + this.itemid, {
        scale: 2,
        duration: 0.65,
        ease: "power2",
      });
    },
    onItemHover() {
      gsap.to("#" + this.itemid, {
        x: 15,
        duration: 0.65,
        ease: "power2",
      });
    },
  },
};
</script>
