<script setup>
import gsap from "gsap";

function onBeforeEnter(el) {
  gsap.set(el, {
    scaleX: 0.25,
    scaleY: 0.25,
    x: 0,
    opacity: 0.25,
  });
}

function onEnter(el, done) {
  gsap.to(el, {
    duration: 0.75,
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
    ease: "elastic(5, 0.75)",
    onComplete: done,
  });
}

function onLogoClick() {}
</script>

<template>
  <nav>
    <router-link to="/" @click="onLogoClick">
      <img src="@/assets/logo2.png" class="logo" />
    </router-link>
    <div id="backbutton">
      <transition @before-enter="onBeforeEnter" @enter="onEnter" :css="false">
        <router-link
          v-if="subPage()"
          :to="backNav"
          id="back-to-top-button"
          class="back-button"
        >
          ←
        </router-link>
      </transition>
    </div>
  </nav>
  <router-view
    v-slot="{ Component }"
    @setBackAnchor="setBackAnchor"
    :backNavAnchor="backElementId"
  >
    <Transition>
      <component :is="Component" />
    </Transition>
  </router-view>
  <PageFooter />
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

nav {
  position: fixed;
  display: flex;
  top: 0px;
  right: 0px;
  width: 100vw;
  height: 92px;
  background-position-y: -96px;
  transition: background-position-y 2s ease-in-out;
  background-repeat: no-repeat;
}

.logo {
  align-self: flex-end;
  max-height: 72px;
  max-width: 196px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));
}

.back-button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: block; /* Make the entire item a clickable block */
  text-decoration: none; /* Remove the default link underline */
  position: fixed;
  text-align: center;
  line-height: 72px;
  top: 20px;
  left: 20px;
  width: 72px;
  height: 72px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: none;
  border-radius: 36px;
  cursor: pointer;
  font-size: xx-large;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.back-button:hover {
  transform: translateY(-3px); /* Add a slight lift on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  filter: brightness(95%); /* Change color on hover */
}
</style>

<script>
import PageFooter from "@/components/PageFooter.vue";
export default {
  name: "App",
  components: {
    PageFooter,
  },
  props: {
    //backNav: String,
  },
  data() {
    return {
      backNav: "/#",
      backElementId: "none",
    };
  },
  methods: {
    subPage() {
      if (this.$route.path == "/") {
        return false;
      } else {
        return true;
      }
    },
    setBackAnchor(anchor) {
      this.backElementId = anchor;
      this.backNav = "/#" + anchor;
    },
  },
};
</script>
